<template>
  <HourglassLoader :loading="loading">
    <section>
      <div class="d-flex justify-content-between align-items-center py-3">
        <div class="d-block">
          <h2 class="h4">Coin Type Details</h2>
        </div>

        <div class="btn-toolbar">
          <button type="button" class="btn btn-sm btn-outline-primary" @click="RefreshData">Reload</button>
        </div>
      </div>

      <div v-if="data" class>
        <div class="card col-12 col-lg-6">
          <h5 class="card-header">{{ data.name }}</h5>
          <div class="card-body">
            <div class="my-2">
              <h5 class="card-title">Name</h5>
              <p class="card-text">{{ data.name }}</p>
            </div>
            <div class="my-2">
              <h5 class="card-title">Rate</h5>
              <p class="card-text">{{ data.usd_rate }}</p>
            </div>
            <div class="my-2">
              <h5 class="card-title">Date</h5>
              <p class="card-text">{{ new Date(data.created_at).toLocaleString() }}</p>
            </div>
            <div class="my-2">
              <h5 class="card-title">ID</h5>
              <p class="card-text">{{ data.id }}</p>
            </div>
          </div>
        </div>
        <!-- <div class="col-12 pb-5">
          <div class="card card-body border-light shadow-sm">
            <div class="row text-center py-3">
              <div class="col-12 col-md-4">
                <h4 class="text-gray">Name</h4>
                <h6>{{ data.name }}</h6>
              </div>
              <div class="col-12 col-md-4">
                <h4 class="text-gray">USD Rate</h4>
                <h6>{{ data.usd_rate }}</h6>
              </div>
            </div>

            <div class="row">
              <div class="text-center col-12 col-md-4">
                <h4 class="text-gray">Created At</h4>
                <h5 class="fw-bolder">{{ new Date(data.created_at).toLocaleString()}}</h5>
              </div>
              <div class="text-center col-12 col-md-6">
                <h4 class="text-gray">Coin ID</h4>
                <h6>{{ data.id }}</h6>
              </div>
            </div>
          </div>
        </div>-->
      </div>
    </section>
  </HourglassLoader>
</template>
  
  <script lang="ts">
import moment from "moment";
import HourglassLoader from "@/components/loader/HourglassLoader.vue";
import { onBeforeMount, ref, defineComponent, inject } from "vue";
import { apiGet, apiPost } from "@/api";
import { useRoute } from "vue-router";
import { Toast } from "@/utils/notify";
import Swal from "sweetalert2";

export default defineComponent({
  name: "CoinTypeDetail",
  components: {
    HourglassLoader,
  },
  setup() {
    const loading = ref(false);
    const data = ref(null);

    const route = useRoute();

    const formatDate = (date: any) => {
      return moment(date).format("LLLL");
    };

    const RefreshData = async () => {
      loading.value = true;
      try {
        const response = await apiGet(
          `/admin/coin/${route.params.coinId}/coin-types`
        );
        data.value = response.data.data.coinType;

        Toast.fire({
          icon: "success",
          title: "Coin Detail Fetched Successfully",
        }).then();
      } catch (e) {
        Toast.fire({
          icon: "warning",
          title: "Unable to fetch data: " + e.message,
        }).then();
      }
      loading.value = false;
    };

    onBeforeMount(RefreshData);

    return {
      RefreshData,

      route,
      data,
      loading,
    };
  },
});
</script>
  
  <style scoped></style>
  